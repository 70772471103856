import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-modal-routing'
import Img from 'gatsby-image/withIEPolyfill'
import { css } from '@emotion/core'

import Layout from 'templates/layout'
import SEO from 'components/seo'
import Section from 'components/organisms/section'

export default ({
  data: {
    background,
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <SEO
      title="取り扱いブランド一覧"
      description="sieniで取り扱っているブランドを掲載しています。"
    />
    <Img
      className="t-brandsIndex__background"
      fluid={background.childImageSharp.fluid}
      objectFit="cover"
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: 0.2,
      }}
    />
    <div className="t-brandsIndex">
      <div className="t-brandsIndex__side">
        <Img fluid={background.childImageSharp.fluid} />
      </div>
      <Section title="Brand" sub="取扱いブランド">
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.25em;
          `}
        >
          {edges &&
            edges.map(({ node: brand }) => {
              if (brand.frontmatter.direct) {
                return (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={brand.frontmatter.url}
                    style={{
                      backgroundColor: '#fcfcfc',
                    }}
                  >
                    <Img
                      fluid={brand.frontmatter.image.childImageSharp.fluid}
                    />
                  </a>
                )
              }
              return (
                <Link
                  to={brand.fields.slug}
                  asModal
                  css={({ color }) => css`
                    background-color: ${color.white};
                  `}
                >
                  <Img fluid={brand.frontmatter.image.childImageSharp.fluid} />
                </Link>
              )
            })}
        </div>
      </Section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    background: file(relativePath: { regex: "/background/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "brands" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            direct
            url
          }
        }
      }
    }
  }
`
